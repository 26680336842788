import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  const post = data.markdownRemark
  let imageURL = require("../pages/blog/images/vintage-logo-alphabet-19.jpg")
  if (post.frontmatter.url.publicURL) {
      imageURL = post.frontmatter.url.publicURL
  }
  return (
    <Layout>
      <div className="blog">
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>
        <img src={imageURL} alt={post.frontmatter.title} />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
      <Link to="/blog">Back</Link>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MM/DD/YYYY")
        url {
            publicURL
          }
      }
    }
  }
`